<template>
    <div>
        <template v-if="type === 'ad'">
            <a
                v-if="openAdAccount(item)"
                :href="openAdAccount(item)"
                target="_blank"
                class="mr-1">
                {{ item.adAccountExternal.name }}
            </a>
            <span v-else>
                {{ item.adAccountExternal.name }}
            </span>
        </template>
        <template v-else>
            <router-link
                :to="{
                    name: 'dealer',
                    params: { dealer_id: item.dealerId }
                }">
                {{ item.dealerName }}
            </router-link>
        </template>
    </div>
</template>

<script>
import { getPlatformAdAccountUrl } from '@/helpers/platformUrls';

export default {
    props: {
        item: {
            type: Object,
            required: true
        },
        platform: {
            type: String,
            required: true
        },
        type: {
            type: String,
            default: ''
        }
    },
    methods: {
        openAdAccount(item) {
            if (item?.businessManager?.external_business_manager_id && item?.adAccount?.external_ad_account_id) {
                return getPlatformAdAccountUrl(
                    this.platform,
                    item.businessManager.external_business_manager_id,
                    item.adAccount.external_ad_account_id,
                );
            }
            return null;
        },
    }
};
</script>
